
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  background: #333;
  color: #fff;
  font-size: 1.1em;
  line-height: 1.5;
  text-align: center;
}
.container {
  padding: 0 auto;
}


.container {
  overflow-y: scroll;
  scroll-behavior: smooth;
}


ul li img {
  display: block;
  width: 100%;
  height: auto;
}

#showcase img {
  display: block;
  width: 70%;
  height: auto;
  margin: auto;
}

h1, h2, h3 {
  margin: 0;
  padding: 1em 0;
}

p {
  margin: 0;
  padding: 1em 0;
}

.btn {
  width: 90px;
}

.btn, .btn-small {
  display: inline-block;
  background: rgba(7, 6, 6, 0.788);
  color: white;
  text-decoration: none;
  padding: 0.5em 2em;
  border: 0.1px solid rgb(82, 81, 81);
  opacity: 0.9;
}

.btn-small {
  width: 1px;
  height: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5%;
  /* margin-top: 13%; */
}


@media (hover: hover) and (pointer: fine) {
  a:hover, div .btn-small:hover {
    background: #eaeaea;
    color: rgb(41, 39, 39);
  }
}

#showcase {
  min-height: 450px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

#showcase .nav-bg-img {
  position: absolute;
  background: #333 url('./images/pexels-g-tan-4664063.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 450px;
  z-index: -1;
  opacity: 0.7;
}

#showcase h1 {
  padding-top: 100px;
  padding-bottom: 0;
}

#showcase .content-wrap,
#sec-2 .content-wrap,
#sec-3 .content-wrap  {
  padding: 0 1.5em;
}

#showcase .content-wrap {
  margin-top: 0em;
}

/*** sec-1 ***/

#sec-1 {
  background: #f8f5f8;
  color: #333;
  /*margin-top: 4em;
  padding-top: 2em;*/
  padding-bottom: 2em;
  padding-bottom: 52px;
  font-size: 1.5rem;
}


div.slider-item {
  padding-top: 1em;
  height: 230px;
  border-radius: 5px;
  width: 270px;
  font-size: 0.8rem;
  display: flex;
  align-items:flex-start;
  justify-content: center;
}

.rec-carousel-item { 
  background-color: transparent;
    -webkit-box-shadow:0 1px 1px rgba(247, 168, 243, 0.3), 0 0 2px rgba(235, 132, 195, 0.1) inset;
       -moz-box-shadow:0 1px 1px rgba(241, 200, 245, 0.3), 0 0 2px rgba(207, 129, 194, 0.1) inset;
            box-shadow:0 1px 1px rgba(217, 165, 219, 0.3), 0 0 2px rgba(179, 105, 163, 0.1) inset;
 /* margin-bottom: 2px;*/
  margin-top: 2px;
}

.rec-carousel-item div:focus {
    outline: none;
    box-shadow: inset 0 0 1px 1px transparent;
}

.rec .rec-pagination .rec-dot_active{
    background: transparent;
    box-shadow: 0 0 1px 2.3px rgba(110, 48, 107, 0.644);
}
.rec .rec-dot:hover {
    cursor: pointer;
    box-shadow: 0 0 1px 2px rgba(130, 85, 180, 0.678);
}

 .slider-icon {
    color: rgb(160, 60, 155);
}
 .slider-icon-disabled {
    color: rgb(156, 150, 156);
    font-size: smaller;
}


.slider-button {
  background-color: transparent;
  border-width: 0;
}
.slider-button:focus {
  outline-style: none;
}

.slider-image {
  width: 100%;
  height: 170px;
  /*height: 100px;*/
}

/*** sec-2 ***/

#sec-2 {
  color: #333;
  padding-top: 2em;
  padding-bottom: 2em;
  text-align: justify;
  position: relative;
  background: #eaeaea;/*
  top: calc( 3rem - 4rem / 2);*/
}

.hide {
  white-space:pre-wrap;
  overflow: hidden;
  display:inline-block;
  text-overflow: ellipsis;
  transition: max-height 1s;
}

.show {
  max-height: 100%;
  white-space: normal; 
  overflow: visible;
  height: 100%; 
}





/*** sec-3 ***/

#sec-3 {
  background: #fff;
  color: #333;
  padding: 2em;
  text-align: left;
}




/*** Footer ***/
#main-footer {
  padding: 0 2em;
  background: #000;
  color: #fff;
  text-align: center;
}

#main-footer a {
  color: #2690d4;
  text-decoration: none;
}





@media(min-width: 700px) {
  .grid {
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(auto, 25em)) 1fr;
  }

  #header-showcase {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  #sec-3 .content-text {
    columns: 2;
    column-gap: 2em;
  }

  #sec-3 .content-text p {
    padding-top: 0;
  }

  .content-wrap  {
    grid-column: 2/4;
  }

  .box, #main-footer div {
    grid-column: 2/4;
  }

  .footer-wide {
    font-size: 0.9em;
    display: flex;
    flex:1;
  }

  #margin-h1 {
    justify-content: center;
    flex: 0.5;
  }
  .footer-wide #margin-p {
    flex: 0.5;
    display: flex;
    justify-content: flex-start;
    margin-left: 9%;
  }
  .footer-wide .d-f {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

#showcase .content-wrap  {
  margin-left: 3em;
}

}
@media(min-width: 700px) {
#showcase .content-wrap  {
  margin-left: 1.7em;
}

}


@media(min-width: 1000px) {
#showcase .content-wrap  {
  margin-left: 3.5em;
}

}


@media(max-width: 500px) {
#showcase img {
  width: 90%;
}
body {
  font-size: 0.9em;
}
footer h1 {
  padding: 0.3em 0 0 0.3em;
}
  .footer-wide .d-f .btn-small {
    margin: auto;
    
}
}
@media(max-width: 360px) {
.btn-small {
  margin-top: 0%;
  margin-left: 39%;
}
h2, h1{
  font-size: 1.2em;
}
  .footer-wide .d-f .btn-small {
    margin: auto;
    
}

.slider-image {
  height: 150px;
  /*height: 100px;:nth-child(3)
  width: 100%;*/
}
}


@media(min-width: 501px) and (max-width: 699px){
footer h1 {
  padding: 0.3em 0 0 0.3em;
}
  .footer-wide .d-f .btn-small {
    margin: auto;
    
}
}